import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/gethotel',
    name: 'gethotel',
    component: () => import('../views/GetHotel.vue')
  }  ,
  {
    path: '/st',
    name: 'SetOfView',
    component: () => import('../views/SetOfView.vue')
  } ,
  {
    path: '/yk',
    name: 'SetykView',
    component: () => import('../views/SetykView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
