import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import axios from 'axios'

 //全局变量根路径
axios.defaults.baseURL='/api'
//axios.defaults.baseURL="/"



createApp(App).use(router).use(ElementPlus).use(ElementPlus, {locale: zhCn,}). mount('#app')

//createApp(App).use(router).use(ElementPlus).use(ElementPlus,{locale: zhCn}).mount('#app')


